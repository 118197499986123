import React from 'react';
import './index.css';

const Payment = () => (
	<div className="payment">
		<h1>Готівковий і безготівковий розрахунок. Самовивіз. доставка:</h1>
		<h2>
			Готівковий, безготівковий розрахунок і самовивезення замовленого товару ви можете здійснити безпосередньо в
			нашому магазині "Форма НАТО".
		</h2>
	</div>
);

export default Payment;
