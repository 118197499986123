import React from 'react';
import './index.css';


const Logo = () => (
    <div className="logo">
        <img src="https://i.imgur.com/7rJBSw8.png" alt="logo" />
    </div>
);

export default Logo;
