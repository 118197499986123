import React from 'react';
import './index.css';
import { api } from '../../utils/api';
import { camouflage } from './data';

const TypeCamouflage = () => (
	<div className="typeCamouflage">
		<h2>Камуфляж - види і забарвлення</h2>
		<p>
			Камуфляж в перекладі з французького означає «маскування» і призначений для утруднення розпізнавання
			вірогідним супротивником контурів бійця місцевості при использовния візуального, фото-, і оптоелектронного
			способа.Расцветка камуфляжу являє собою багатобарвний (2-4 кольори) плямистий малюнок (великі, або дрібні
			плями) , спотворюють і розмивають контури бійця.
		</p>
		<p>
			Першим в світі камуфляжем було забарвлення "хакі" під час Англо-бурської війни (1899-1902 рр) - яка велася
			за незалежність Трансваалю від Англії. Англійці, що носили червоні мундири, відповідно несли великі Потро,
			проти бурів. умевшіхіспользовать місцевість для маскування. Внаслідок чого англійців переодягнули у форму
			болотяного кольору (хакі). Під час Другої світової війни Гераманія мала на озброєння близько 30 різних
			кольорів камуфляжу, деякі з яких потім перекочували в Радянську армію, наприклад, камуфляж "берізка",
			використовуються і модернізуються і до цього день.
		</p>
		<p>
			Починаючи з 60-х років, розвиток видів камуфляжу пішло великими темпами. Необхідно розуміти, що
			універсальних камуфляжів не буває в принципі, кожна забарвлення створювалася і покликана працювати тільки в
			певних условіях.А лучшімй камуфляж, це той, який ви можливо зробите самі під конкретну місцевість і умови.
			Далі розглянемо основні види камуфляжу різних країн. При цьому всілякі камуфляжі розглядатися не будуть,
			оскільки більшість варіацій забарвлень камуфляжу злизані з інших.
		</p>
		<h2>Види і забарвлення камуфляжу</h2>

		{camouflage.map((c, i) => {
			return (
				<div key={i}>
					<h3>{c.title}</h3>
					<img src={`${api}${c.photo}`} alt={c.title} />
					{c.text.map(p => {
						return <p key={p}>{p}</p>;
					})}
				</div>
			);
		})}
	</div>
);

export default TypeCamouflage;
