import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { article } from './articles';

const Reviews_Analytics = () => (
	<div className="reviews_analytics">
		<div className="reviews">
			<h3>Огляди</h3>
			<ul>
				<Link to={article[0].path}>
					<li>{article[0].title}</li>
				</Link>
				<Link to={article[1].path}>
					<li>{article[1].title}</li>
				</Link>
				<Link to={article[2].path}>
					<li>{article[2].title}</li>
				</Link>
			</ul>
		</div>
		<p>
			<strong>Камуфляж</strong> - пятнистая маскировочная окраска, применяемая для уменьшения заметности людей,
			вооружений, техники, сооружений за счет размывания силуэта изделия или предмета.
		</p>
		<p>
			Постійно збільшувалися точність і далекобійність зброї, а також оволодіння повітряним простором привели до
			появи військового спорядження, забезпечував максимальну маскування в різних ситуаціях, не обмежуючи при
			цьому свободу руху. Існують одноколірні і багатоколірні камуфляжі. Самий «популярний» колір однотонного
			камуфляжу, з яким він зазвичай і асоціюється - колір хакі. Для маскування в різних видах лісів, в пустелях,
			в снігах.
		</p>
		<p>
			Польова камуфляжна форма стала цілеспрямовано шитися з тканини захисно-маскувального кольору в кінці XIX -
			початку XX століття. В кінці XIX століття британські колоніальні війська в Індії перейняли колір хакі,
			перською мові означає «кольору пилу». Камуфляжна форма кольору хакі успішно показала себе на полі бою в
			південноафриканської війні з бурами 1899-1902 рр .. Все принципові схеми армійських камуфляжних малюнків
			розроблялися під конкретну місцевість, на якій знаходяться військовослужбовці, з урахуванням нормативних
			вимог, що пред'являються до маскування на відкритій місцевості. За основу береться людський зір в світлий
			час доби, що є точкою відліку при складанні колірної насиченості малюнка, його геометричної побудови,
			контрастності між межують фрагментами. Всі збройні сили світу рано чи пізно прийшли до використання
			камуфляжних малюнків для виготовлення військового екіпірування, щоб уберегти особистий склад при веденні
			бойових дій.
		</p>
		<p>
			Під час Першої світової війни британський художник і офіцер військово-морського флоту Норман Уілкінсон
			розробив особливу схему маскування для військового флоту - так званий «сліпучий камуфляж» (іноді
			використовується більш загальний термін «деформуючий камуфляж»). Цей камуфляж не намагався заховати судно, а
			утруднював оцінку відстані до нього, його швидкості та курсу. Після 1970-х років більшість військових
			кораблів і літаків фарбуються в сірий колір, як самий непомітний і практичний.
		</p>
		<p>
			До початку Другої світової війни камуфляж був повсюдно використаний у всіх родах військ і з урахуванням
			географічних особливостей місця передбачуваного бою. Під час Другої світової використовувався зимовий
			камуфляж - білі маскхалати, надягали поверх шинелей. У 1944 році з'явилися камуфляжні костюми
			світло-зеленого кольору з брудно-сірим малюнком, що імітує листя, або з малюнком, що нагадує нинішній
			«цифровий» камуфляж. Маскувальна розфарбування застосовувалася у Другій світовій війні для різної техніки. В
			основному, це були білила і вапно для зимових варіантів і штатний зелений (трав'яний) колір для літа. Однак,
			деяка частина техніки спочатку йшла в двох-триколірна камуфляжі. Після війни на деякий час ця практика була
			припинена, в зв'язку зі зміною пріоритетів і завдань. Так, наприклад, на літаках-бомбардувальниках стали
			фарбувати «черево» в сніжно-білий колір, для зниження вражаючої дії ядерного вибуху.
		</p>
		<p>
			Posle voyny potrebnost' delat' kamuflyazhnuyu formu pochti otpala. Odnako v kontse pyatidesyatykh godov
			dvadtsatogo veka v svyazi prisvoyenii deystviyami v Yugo-Vostochnoy Azii kamuflyazhnyy bum snova imel mesto.
			Dlya armii SSHA togda sshili 43 maskirovochnykh komplekta, sredi nikh: «pustynya», «les», «dzhungli», «zima»
			i drugiye. Ещё 315 / 5000 Результаты перевода Після війни потреба робити камуфляжну форму майже відпала.
			Однак в кінці п'ятдесятих років двадцятого століття в зв'язку присвоєння діями в Південно-Східній Азії
			камуфляжний бум знову мав місце. Для армії США тоді пошили 43 маскувальних комплекту, серед них: «пустеля»,
			«ліс», «джунглі», «зима» та інші.
		</p>
		<p>
			В кінці 1960-х, будучи поширеним в американських військах, камуфляж приходить і в цивільний одяг в Як
			громадянського протесту проти воєн. Носіння військової форми на вулицях міста повинно було звернути увагу
			населення на те, що б вони відчули, якби в їхньому місті велися бойові дії і ходили солдати. Мода швидко
			пішла, але воскресла в 1980-х, придбавши небувале поширення на початку 1990-х у час війни в Перській затоці.
		</p>
		<p>
			Американці використовували камуфляж до війни у В'єтнамі, існував камуфляж «Дакхантер», який використовується
			2-й бронетанкової дивізією при «Операція Кобра», але збільшення кількості випадків вогню за своїми цей
			камуфляж був знятий, в основному обмежувалися прикріпленням до своїх шоломів гілочок, трави, листя.
		</p>
		<p>
			У 1984 році був створений «цифровий» камуфляж: на солдатську форму був нанесений малюнок, схожий на
			конфігурацію пікселів на екрані монітора. Даний малюнок робить непомітним пересування військовослужбовців.
		</p>
		<p>
			В даний час існує загальне прагнення кожної армії мати один універсальний малюнок, допрацьовує колірні
			рішення цього малюнка в залежності від типу місцевості. Такі існуючі в різних колірних рішеннях ACUPAT
			(наприклад, в варіантах «пустельний», «міський» і «лісової», базовим вважається «urban» - міський варіант в
			сірих тонах), DPM, Flektarn і інші. Іноді відособлена частина Збройних сил може мати власний малюнок, проте
			і в цьому випадку помітно і сама тенденція (наприклад, використовуваний корпусом морської піхоти США MARPAT,
			хоча і відрізняється від використовуваного іншими Збройними силами єдиного малюнка ACUPAT, також має кілька
			колірних рішень, використовуваних в залежності від місцевості, базовим вважається варіант «woodland» -
			лісовий, в зелено-коричневих тонах). Перш за все (але не виключно) це пов'язано з як і раніше актуальною
			проблемою розпізнавання «свій-чужий».
		</p>
		<p>
			Модна індустрія почала виробляти псевдовійськової розмальовки більш м'яких тканин, імітувати фурнітуру під
			відмінності. Джон Гальяно, Анна Сюи, Commes des Garsons перенесли риси військової форми на викрійки спідниць
			і суконь. Камуфляж міцно закріпився в спортивному одязі. Також камуфльований одяг користується певним
			попитом у мисливців, рибалок і частини туристів.
		</p>

		<p>
			У деяких субкультурах, таких як ріветхеди, деякі напрямки в готиці, кіберпанк, панк і особливо в культурі
			сталкерів одяг камуфляжній забарвлення є важливим і необхідним елементом. камуфляжний одяг носять в
			поєднанні з армійськими черевиками або масивними кросівками, шкіряними Беспалов рукавичками і прикрасами в
			панк-стилі.
		</p>
	</div>
);

export default Reviews_Analytics;
