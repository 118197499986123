import React from 'react';
import './index.css';

const Contacts = () => (
	<div className="contacts">
		<p>
			<strong>Наша адреса:</strong>
		</p>
		<p> Україна. Київ. Вулиця Семена Скляренка 17 (ближче до Кирилівської площі) </p>
		<p> За магазином є парковка </p>
		<p>
			<strong>Доїхати на громадському транспорті:</strong>
		</p>
		<p>Зупинка "Вулиця Семена Скляренка" трамваїв: №11, №12, №16, №17, №19</p>
		<p>Зупинка "Куренівський ринок" тролейбусів: №6, №6К, №18, №25, №28, №33 и автобуса №72</p>
		<p>Зупинка "Кінотеатр 'Кадр'" автобуса №32</p>
		<p>Станція міської електрички "Вишгородська"</p>
		<p>
			<strong>Контактні телефони:</strong>
			<p>+38 (066) 365-77-81</p>
			<p>+38 (096) 897-33-07</p>
		</p>
		<p>
			<strong>Пошта:</strong>
			<p>nato-uniform@ukr.net</p>
		</p>

		<iframe
			title="Contacts"
			src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d897.3163951361358!2d30.46316430192404!3d50.49706884007085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1suk!2sua!4v1504709153919"
			style={{
				width: '670px',
				height: '450px',
				frameborder: '0',
				style: 'border:0'
			}}
		/>

		<img
			src="https://nato-uniform.com.ua/images2/magazin.jpg"
			style={{
				width: '670px',
				height: '450px'
			}}
			alt="Магазин Форма NATO.Киев.Куренёвка"
			title="Магазин Форма NATO.Киев.Куренёвка"
		/>
	</div>
);

export default Contacts;
