import React from 'react';
import { Redirect } from 'react-router-dom';
import { tokenCheck } from '../../utils/api';
class AdminRoute extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: localStorage.getItem('token'),
			status: undefined,
			loading: true
		};
	}

	async componentDidMount() {
		if (localStorage.getItem('token')) {
			const token = localStorage.getItem('token');
			const result = await tokenCheck(token)
				.then(r => {
					return r.json();
				})
				.catch(err => console.log(err));

			if (result) {
				this.setState({ status: result.status, token: result.token, loading: false });
				localStorage.removeItem('token');
				localStorage.setItem('token', result.token);
			} else {
				localStorage.removeItem('token');
			}
		}
		if (!localStorage.getItem('token')) {
			this.setState({ loading: false });
		}
	}

	render() {
		return this.state.loading === true ? (
			''
		) : this.state.status === 200 && this.state.loading === false ? (
			this.props.children
		) : (
			<Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
		);
	}
}

export default AdminRoute;
