import React from 'react';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { api } from '../../utils/api';

const galery = [
	'inside1-min.jpg',
	'inside2-min.jpg',
	'inside3-min.jpg',
	'inside4-min.jpg',
	'inside5-min.jpg',
	'inside6-min.jpg',
	'inside7-min.jpg',
	'inside8-min.jpg',
	'inside9-min.jpg',
	'inside10-min.jpg',
	'inside11-min.jpg',
	'inside12-min.jpg',
	'inside13-min.jpg',
	'inside14-min.jpg',
	'inside15-min.jpg',
	'inside16-min.jpg'
];

const Home = () => (
	<div className="container-home">
		<div className="products-top">
			<img src={`${api}home-min.jpg`} alt="home" />
		</div>
		<div className="products-top">
			<img src={`${api}home2.gif`} alt="home2" />
		</div>
		<Slider
			dots={false}
			slidesToShow={1}
			slidesToScroll={1}
			autoplay={true}
			autoplaySpeed={3000}
			className="slider"
			arrows={false}
		>
			{galery.map((num, i) => (
				<div key={i}>
					<img src={`${api}${num}`} alt="galery" />
				</div>
			))}
		</Slider>
	</div>
);

export default Home;
