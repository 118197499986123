import React from 'react';
import './index.css';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import { getClothByCategory } from '../../utils/api.js';
import NotFound from '../NotFound';

class Products extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: '',
			page: this.props.match.params.id,
			maxCount: null,
			error: false,
			loading: true
		};
		this.handleChangePagination = this.handleChangePagination.bind(this);
	}
	async componentDidMount() {
		const clothByCategory = await getClothByCategory(this.props.category, this.state.page).catch(err =>
			console.log(err)
		);

		if (clothByCategory.statusCode === 404) {
			this.setState({ error: true, loading: false });
			return;
		}

		this.setState({ data: clothByCategory.cloth, maxCount: clothByCategory.maxCount, loading: false });
	}

	async componentDidUpdate(prevProps) {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		if (this.props.match.params.id !== prevProps.match.params.id) {
			const clothByCategory = await getClothByCategory(
				this.props.category,
				this.props.match.params.id
			).catch(err => console.log(err));

			if (clothByCategory.statusCode === 404) {
				this.setState({ error: true, loading: false });
				return;
			}

			this.setState({
				data: clothByCategory.cloth,
				maxCount: clothByCategory.maxCount,
				loading: false,
				page: this.props.match.params.id,
				error: false
			});
		}
	}
	handleChangePagination(e, v) {
		e.preventDefault();
		this.props.history.push(`/${this.props.category}/${v}`);
	}
	render() {
		return (
			<>
				{this.state.loading === true ? (
					<Spinner />
				) : this.state.error === true ? (
					<NotFound />
				) : (
					<>
						<div className="products-container">
							<div className="products-warp">
								<div className="products">
									{this.state.data.map((product, i) => (
										<Link to={`/products/${product.id}`} key={i}>
											<div className="cards">
												<img src={`/${product.image_preview}`} alt={product.name} />

												<p className="products-name">{product.name.toUpperCase()}</p>
												<p> Код товару: {product.article}</p>
												{product.new_price ? <h4> Ціна: {product.new_price} грн.</h4> : ''}
												{product.used_price ? (
													<h4> Ціна б/у: {product.used_price} грн.</h4>
												) : (
													''
												)}
											</div>
										</Link>
									))}
								</div>
							</div>
							<div className="products-pagination">
								<Pagination
									page={Number.parseInt(this.state.page)}
									count={Math.ceil(this.state.maxCount / 9)}
									variant="outlined"
									shape="rounded"
									onChange={this.handleChangePagination}
								/>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

export default Products;
