import React from 'react';
import './index.css';

const Article = props => (
	<div className="article-container">
		<iframe
			width="815"
			height="561"
			src={props.link}
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		></iframe>

		<div className="info-container">
			{props.paragraphs.map(p => (
				<>
					<h2 key={p}>{p.title}</h2>
					{p.text.map(t => (
						<p>{t}</p>
					))}
				</>
			))}
		</div>
	</div>
);

export default Article;
