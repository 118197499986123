import React from 'react';
import './index.css';

import routes from '../../utils/routes';
import { Link } from 'react-router-dom';

class Sidebar extends React.Component {
	render() {
		return (
			<section className="sidebar">
				<section className="sidebar-products">
					<strong className="sidebar-title">Товари</strong>
					<ul>
						{routes.map((p, i) => {
							return (
								<Link to={`/${p.path}/1`} key={i}>
									<li>{p.name}</li>
								</Link>
							);
						})}
					</ul>
				</section>
				<section className="sidebar-products">
					<strong className="sidebar-title">Графік роботи:</strong>
					<p>Вт-Пт: з 9:00 до 18:00</p>
					<p>Сб:    з 9:00 до 17:00</p>
					<p>Нд-Пн: вихідний</p>
				</section>

				<section className="sidebar-products">
					<Link to={'/camouflage'} className="side-link">
						<p>Види камуфляжу</p>
					</Link>
				</section>
			</section>
		);
	}
}

export default Sidebar;
