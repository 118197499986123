const routes = [
    {
      path: "kurtky-parky",
      exact: true,
      name: "Куртки, Парки"
    },
    {
      path: "rubashki-kitelya",
      exact: true,
      name: "Сорочки, Кітеля"
    },
    {
      path: "bryuki",
      exact: true,
      name: "Штани"
    },
    {
      path: "t-shirts-polo",
      exact: true,
      name: "Футболки, Майки, Поло"
    },
    {
      path : "shorts",
      exact: true,
      name: "Шорти"
    },
      {
        path: "komplekty-uniformy",
        exact: true,
        name: "Комплекти уніформи"
      },
      {
        path: "maskirovochnaya-odezhda",
        exact: true,
        name: "Маскувальний одяг"
      },
      {
        path: "dozhdeviki",
        exact: true,
        name: "Дощовики"
      },
      {
        path: "kombinezony",
        exact: true,
        name: "Комбінезони"
      }, 
      {
        path: "svitera-flisy",
        exact: true,
        name: "Светри, Фліси, Кофти"
      },
      {
        path: "podstyozhki-utepliteli",
        exact: true,
        name: "Підстібки, Утеплювачі"
      },
      {
        path: "thermal-underwear",
        exact: true,
        name: "Термобілизна"
      }, 
      {
        path: "golovnye-ubory",
        exact: true,
        name: "Головні убори, Шарфи, Шемаги"
      },
      {
        path: "perchatki",
        exact: true,
        name: "Рукавички"
      },
      {
        path: "remni-podtyazhki",
        exact: true,
        name: "Ремені, Підтяжки"
      },
      {
        path: "obuv-bahily",
        exact: true,
        name: "Взуття, Бахіли"
      },
      {
        path: "noski",
        exact: true,
        name: "Шкарпетки"
      },
      {
        path: "backpacks-bags",
        exact: true,
        name: "Рюкзаки, Сумки, Баули"
      },
      {
        path: "karemats-sleepingbags",
        exact: true,
        name: "Каремати, Cпальнi мiшки"
      },
      {
        path: "dishes-flasks",
        exact: true,
        name: "Посуда, Фляги, Гiдросистема"
      },
      {
        path: "knives-shovels-tools",
        exact: true,
        name: "Ножи, Лопати, Інструменти"
      },
      {
        path: "ochki",
        exact: true,
        name: "Окуляри, Оптика"
      },
      {
        path: "snaryazheniye-i-ekipirovka",
        exact: true,
        name: "Спорядження та екіпіровка"
      },
      {
        path: "aksessuary",
        exact: true,
        name: "Аксесуари"
      }
    
  ];


export default routes;  