import React from 'react';
import './index.css';
import { getClothById } from '../../utils/api.js';
import NotFound from '../NotFound';

class Product extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			images: '',
			main_img: '',
			id: this.props.match.params.productId,
			cloth: '',
			loading: true
		};
	}
	async componentDidMount() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
		const clothByid = await getClothById(this.state.id).catch(err => console.log(err));

		if (clothByid.statusCode === 404) {
			this.setState({ loading: false });
			return;
		}

		this.setState({ cloth: clothByid, main_img: clothByid.image_preview });

		if (!clothByid.images) {
			this.setState({ images: this.state.main_img });
			return;
		}

		const temp = [this.state.main_img, ...this.state.cloth.images];
		this.setState({ images: temp, loading: false });
	}

	handleTarget(img, e) {
		e.preventDefault();
		let Imgfilter = this.state.images.filter((p, i) => p === img);
		this.setState({ main_img: Imgfilter[0] });
	}

	render() {
		return (
			<div className="product-container">
				{this.state.loading === true ? (
					''
				) : (
					<>
						{this.state.cloth ? (
							<>
								<div className="images-container">
									<img src={`/${this.state.main_img}`} alt={this.state.cloth.name} />
									<div className="images-small-slid">
										{this.state.images
											? this.state.images.map((p, i) => {
													return (
														<img
															className={p === this.state.main_img ? 'act' : 'b'}
															src={`/${p}`}
															alt={p}
															key={i}
															onClick={this.handleTarget.bind(this, p)}
														/>
													);
											  })
											: ''}
									</div>
								</div>
								<div className="product-info">
									<h2>{this.state.cloth.name.toUpperCase()}</h2>
									<p>Код товару: {this.state.cloth.article}</p>

									{this.state.cloth.available ? (
										this.state.cloth.available === 'true' ? (
											<p>Товар є в наявності</p>
										) : (
											<p>Товару немає в наявності</p>
										)
									) : (
										''
									)}

									{this.state.cloth.new_price ? (
										<h3> Ціна: {this.state.cloth.new_price} грн.</h3>
									) : (
										''
									)}
									{this.state.cloth.used_price ? (
										<h3>Ціна б/у: {this.state.cloth.used_price} грн.</h3>
									) : (
										''
									)}

									{this.state.cloth.color ? <p>Колір: {this.state.cloth.color}</p> : ''}

									{this.state.cloth.producer ? <p>Виробник: {this.state.cloth.producer}</p> : ''}
									{this.state.cloth.material ? <p>Матеріал: {this.state.cloth.material}</p> : ''}
									{this.state.cloth.country ? <p>Країна: {this.state.cloth.country}</p> : ''}
									{this.state.cloth.description ? (
										<pre>
											<p>{this.state.cloth.description}</p>
										</pre>
									) : (
										''
									)}
								</div>
							</>
						) : (
							<NotFound />
						)}
					</>
				)}
			</div>
		);
	}
}

export default Product;
