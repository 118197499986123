import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import routes from '../../utils/routes';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { getClothByName, createNewCloth, deletePhoto, updateCloth, deleteById } from '../../utils/api.js';

const initState = {
	id: '',
	name: '',
	article: '',
	country: '',
	material: '',
	new_price: '',
	category: routes[0].path,
	description: '',
	images: '',
	color: '',
	producer: '',
	available: true,
	search: '',
	image_preview: '',
	used_price: '',
	search_status: false,
	error: false,
	open: false,
	vertical: 'top',
	horizontal: 'center'
};

const styles = theme => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
			display: 'flex'
		},
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '600px'
		},
		'& .MuiInput-root': {
			margin: theme.spacing(2)
		}
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	input: {
		display: 'none'
	},
	images: {
		flexWrap: 'wrap',
		width: '650px',
		'& img': {
			width: '150px'
		}
	},
	images_container: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	button_conteiner: {
		width: '100%'
	}
});

class AdminDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...initState, token: '' };

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeImages = this.handleChangeImages.bind(this);
		this.handleSubmitClothes = this.handleSubmitClothes.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleChangePrewiev = this.handleChangePrewiev.bind(this);
		this.handleCloseAlert = this.handleCloseAlert.bind(this);
		this.handleDeleteClothById = this.handleDeleteClothById.bind(this);
	}
	componentDidMount() {
		this.setState({ token: localStorage.getItem('token') });
	}

	async handleDeleteClothById(event) {
		event.preventDefault();
		const id = this.state.id;
		const token = this.state.token;
		const img = [...this.state.image_preview, ...this.state.images.flat()];

		await deleteById(id, token, JSON.stringify(img));
		this.setState(initState);
	}

	handleCloseAlert() {
		this.setState({ open: false, vertical: 'bottom', horizontal: 'right' });
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleChangeImages(event) {
		const files = event.target.files;
		const filesAcepted = [];
		for (let i = 0; i < files.length; i++) {
			filesAcepted.push(files[i].name);
		}
		this.setState({ images: [filesAcepted, files] });
	}

	handleChangePrewiev(event) {
		if (event.target.files.length > 0) {
			this.setState({ image_preview: [event.target.files[0].name, event.target.files[0]] });
		}
	}

	async handleSearch(event) {
		event.preventDefault();
		if (!this.state.search) {
			return;
		}
		const searchLowCase = this.state.search.toLowerCase();
		const data = JSON.stringify({ name: searchLowCase });
		const cloth = await getClothByName(data, this.state.token);
		if (cloth.status === 'error') {
			this.setState({ open: true, vertical: 'bottom', horizontal: 'right' });
			return;
		}

		const formatImg = [cloth.images];
		this.setState({ ...cloth, images: formatImg, search_status: true });
	}

	handleClear(event) {
		event.preventDefault();
		this.setState(initState);
	}

	async handleDelete(src, event) {
		event.preventDefault();
		const images = this.state.images;
		const index = images[0].findIndex(i => i === src);

		if (this.state.images.length === 1) {
			images[0].splice(index, 1);
			this.setState({ images: [images[0]] });
			await deletePhoto(this.state.id, src, this.state.token);
		} else {
			const linkImg = [];
			images[0].splice(index, 1);
			for (let i = 0; i < images[1].length; i++) {
				if (i !== index) {
					linkImg.push(images[1][i]);
				}
			}
			this.setState({ images: [images[0], linkImg] });
		}
	}

	async handleSubmitClothes(event) {
		event.preventDefault();
		let {
			id,
			article,
			name,
			country,
			image_preview,
			material,
			used_price,
			new_price,
			category,
			description,
			images,
			search_status,
			color,
			producer,
			available
		} = this.state;
		article = article.trim();
		name = name.replace(/ +/g, ' ').trim().toLowerCase();
		country = country.trim();
		material = material.trim();
		used_price = used_price.trim();
		new_price = new_price.trim();
		category = category.trim();
		description = description.trim();
		color = color.trim();
		producer = producer.trim();

		if (!name || !image_preview || !article || (!used_price && !new_price)) {
			return;
		}
		const data = new FormData();
		data.append('article', article);
		data.append('name', name);
		data.append('country', country);
		data.append('material', material);
		data.append('new_price', new_price);
		data.append('used_price', used_price);
		data.append('category', category);
		data.append('description', description);
		data.append('color', color);
		data.append('producer', producer);
		data.append('available', available);

		if (images.length > 1) {
			for (let i = 0; i < images[1].length; i++) {
				data.append(`images`, images[1][i]);
			}
		} else {
			if (images) {
				for (let i = 0; i < images[0].length; i++) {
					data.append(`images`, images[0][i]);
				}
			}
		}

		if (image_preview.length > 1) {
			data.append('image_preview', image_preview[1]);
		} else {
			data.append('image_preview', image_preview[0]);
		}

		if (search_status === false) {
			await createNewCloth(data, this.state.token);
		} else {
			data.append('id', id);
			await updateCloth(data, this.state.token);
		}

		this.setState(initState);
	}

	render() {
		const { classes } = this.props;
		return (
			<div>
				<Snackbar
					anchorOrigin={{ vertical: this.state.vertical, horizontal: this.state.horizontal }}
					open={this.state.open}
					onClose={this.handleCloseAlert}
					message="Товар не найден"
					key={this.state.vertical + this.state.horizontal}
				/>
				<div className={classes.container}>
					<form className={classes.root} onSubmit={this.handleSearch}>
						<TextField
							id="outlined-basic"
							label="Товар"
							variant="outlined"
							type="search"
							onChange={this.handleChange}
							name="search"
							value={this.state.search}
						/>
						<div className={classes.button_conteiner}>
							<Button variant="contained" color="primary" type="submit">
								Найти товар
							</Button>

							{this.state.search_status === true ? (
								<Button
									variant="contained"
									color="secondary"
									type="button"
									onClick={this.handleDeleteClothById}
								>
									Удалить найденый товар
								</Button>
							) : (
								''
							)}
						</div>

						<Button variant="contained" color="secondary" type="button" onClick={this.handleClear}>
							Очистить форму
						</Button>
					</form>
				</div>

				<form className={classes.root} onSubmit={this.handleSubmitClothes} encType="multipart/form-data">
					<TextField
						id="outlined-basic"
						label="Код товару"
						variant="outlined"
						type="article"
						onChange={this.handleChange}
						name="article"
						value={this.state.article}
						error={this.state.article ? false : true}
					/>
					<TextField
						id="outlined-basic"
						label="Назва товару"
						variant="outlined"
						type="name"
						onChange={this.handleChange}
						name="name"
						value={this.state.name}
						error={this.state.name ? false : true}
					/>

					<TextField
						id="outlined-basic"
						label="Країна"
						variant="outlined"
						type="country"
						onChange={this.handleChange}
						name="country"
						value={this.state.country}
					/>

					<TextField
						id="outlined-basic"
						label="Колір"
						variant="outlined"
						type="text"
						onChange={this.handleChange}
						name="color"
						value={this.state.color}
					/>

					<TextField
						id="outlined-basic"
						label="Виробник"
						variant="outlined"
						type="producer"
						onChange={this.handleChange}
						name="producer"
						value={this.state.producer}
					/>

					<TextField
						id="outlined-basic"
						label="Матеріал"
						variant="outlined"
						type="material"
						onChange={this.handleChange}
						name="material"
						value={this.state.material}
					/>

					<TextField
						id="outlined-basic"
						label="Ціна для нової"
						variant="outlined"
						type="new_price"
						onChange={this.handleChange}
						name="new_price"
						value={this.state.new_price}
					/>

					<TextField
						id="outlined-basic"
						label="Ціна для Б/У"
						variant="outlined"
						type="used_price"
						onChange={this.handleChange}
						name="used_price"
						value={this.state.used_price}
					/>

					<Select
						labelId="category"
						id="category"
						value={this.state.category}
						onChange={this.handleChange}
						name="category"
					>
						{routes.map((category, i) => (
							<MenuItem value={category.path} key={i}>
								{category.name}
							</MenuItem>
						))}
					</Select>

					<Select
						labelId="available"
						id="available"
						value={this.state.available}
						onChange={this.handleChange}
						name="available"
					>
						<MenuItem value={true}>Товар есть в наличии</MenuItem>
						<MenuItem value={false}>Товар нет в наличии</MenuItem>
					</Select>

					<input
						accept="image/*"
						className={classes.input}
						id="contained-button-file"
						type="file"
						multiple={false}
						onChange={this.handleChangePrewiev}
					/>

					<label htmlFor="contained-button-file">
						<Button variant="contained" color="primary" component="span">
							Загрузить превью фото
						</Button>
					</label>

					<div className={classes.images}>
						{this.state.image_preview !== '' ? <strong>Выбрано фото:</strong> : ''}
						{this.state.image_preview !== '' ? (
							<div>
								<img src={this.state.image_preview} alt={this.state.image_preview} />
							</div>
						) : (
							''
						)}
					</div>

					<input
						accept="image/*"
						className={classes.input}
						id="contained-button-files"
						multiple
						type="file"
						onChange={this.handleChangeImages}
					/>

					<label htmlFor="contained-button-files">
						<Button variant="contained" color="primary" component="span">
							Загрузить фото
						</Button>
					</label>

					<div className={classes.images}>
						{this.state.images !== '' ? <strong>Выбрано фото:</strong> : ''}

						{this.state.images !== ''
							? this.state.images[0].map((p, i) => {
									return (
										<div key={i}>
											<img src={p} alt={p} />
											<Button
												variant="contained"
												color="secondary"
												className={classes.button}
												startIcon={<DeleteIcon />}
												onClick={this.handleDelete.bind(this, p)}
											>
												Delete
											</Button>
										</div>
									);
							  })
							: ''}
					</div>

					<TextField
						id="outlined-multiline-static"
						label="Описание"
						multiline
						rows={30}
						variant="outlined"
						value={this.state.description}
						onChange={this.handleChange}
						type="description"
						name="description"
					/>

					<Button variant="contained" color="primary" type="submit">
						{this.state.search_status === false ? <span>Добавить товар</span> : <span>Изменить товар</span>}
					</Button>
				</form>
			</div>
		);
	}
}

export default withStyles(styles)(AdminDashboard);
