const shoes = [
    { США: 4.5, Європа: 36, Бундесвер: 230 },
    { США: 5, Європа: 36.5, Бундесвер: 235 },
    { США: 5, Європа: 3, Бундесвер: 235 },
    { США: 5.5, Європа: 37.5, Бундесвер: 240 },
    { США: 6, Європа: 38, Бундесвер: 245 },
    { США: 6.5, Європа: 38.5, Бундесвер: 245 },
    { США: 6.5, Європа: 39, Бундесвер: 250 },
    { США: 7, Європа: 39.5, Бундесвер: 255 },
    { США: 7.5, Європа: 40, Бундесвер: 255 },
    { США: 8, Європа: 40.5, Бундесвер: 260 },
    { США: 8, Європа: 41, Бундесвер: 265 },
    { США: 8.5, Європа: 41.5, Бундесвер: 265 },
    { США: 9, Європа: 42, Бундесвер: 270 },
    { США: 9.5, Європа: 42.5, Бундесвер: 275 },
    { США: 9.5, Європа: 43, Бундесвер: 275 },
    { США: 10, Європа: 43.5, Бундесвер: 280 },
    { США: 10.5, Європа: 44, Бундесвер: 285 },
    { США: 11, Європа: 44.5, Бундесвер: 285 },
    { США: 11, Європа: 45, Бундесвер: 290 },
    { США: 11.5, Європа: 45.5, Бундесвер: 295 },
    { США: 12, Європа: 46, Бундесвер: 295 },
    { США: 12.5, Європа: 46.5, Бундесвер: 300 },
    { США: 12.5, Європа: 47, Бундесвер: 305 },
    { США: 13, Європа: 47.5, Бундесвер: 305 },
    { США: 13.5, Європа: 48, Бундесвер: 310 },
    { США: 14, Європа: 48.5, Бундесвер: 315 },
    { США: 14, Європа: 49, Бундесвер: 315 },
    { США: 14.5, Європа: 49.5, Бундесвер: 320 },
    { США: 15, Європа: 50, Бундесвер: 320 }
];

const pants_shorts = [
    {
        'Зріст (см)': '165-175',
        "Об'єм талії": '85',
        Бундесвер: 'Gr.1',
        'Європа, ГОСТ': '44',
        США: 'X/S',
        Австрія: '88-92 I-II'
    },
    {
        'Зріст (см)': '165-175',
        "Об'єм талії": '90',
        Бундесвер: 'Gr.2 ',
        'Європа, ГОСТ': '46',
        США: 'X/M',
        Австрія: '88-92 I-II'
    },
    {
        'Зріст (см)': '165-175',
        "Об'єм талії": '95',
        Бундесвер: 'Gr.3 ',
        'Європа, ГОСТ': '48	',
        США: 'X/L',
        Австрія: '96-100 I-II'
    },
    {
        'Зріст (см)': '165-175',
        "Об'єм талії": '100',
        Бундесвер: '	Gr.4',
        'Європа, ГОСТ': '50',
        США: 'X/XL',
        Австрія: '96-100 I-II'
    },
    {
        'Зріст (см)': '165-175',
        "Об'єм талії": '105',
        Бундесвер: 'Gr.5',
        'Європа, ГОСТ': '52',
        США: '	X/XXL',
        Австрія: '104-108 I-II'
    },
    {
        'Зріст (см)': '175-185',
        "Об'єм талії": '80',
        Бундесвер: 'Gr.6',
        'Європа, ГОСТ': '44',
        США: 'S/R',
        Австрія: '88-92 III-IV'
    },
    {
        'Зріст (см)': '175-185',
        "Об'єм талії": '85',
        Бундесвер: 'Gr.7 ',
        'Європа, ГОСТ': '46',
        США: 'M/R',
        Австрія: '	88-92 III-IV'
    },
    {
        'Зріст (см)': '175-185',
        "Об'єм талії": '90',
        Бундесвер: 'Gr.8 ',
        'Європа, ГОСТ': '48',
        США: 'L/R',
        Австрія: '96-100 III-IV'
    },
    {
        'Зріст (см)': '175-185',
        "Об'єм талії": '95',
        Бундесвер: 'Gr.9 ',
        'Європа, ГОСТ': '50 ',
        США: 'XL ',
        Австрія: '96-100 III-IV '
    },
    {
        'Зріст (см)': '175-185',
        "Об'єм талії": '100',
        Бундесвер: '	Gr.10 ',
        'Європа, ГОСТ': '52',
        США: 'XXL',
        Австрія: '	104-108 III-IV'
    },
    {
        'Зріст (см)': '175-185',
        "Об'єм талії": '105',
        Бундесвер: '	Gr.11 ',
        'Європа, ГОСТ': '54',
        США: 'XXXL',
        Австрія: '104-108 III-IV'
    },
    {
        'Зріст (см)': '185-195',
        "Об'єм талії": '95',
        Бундесвер: 'Gr.12 ',
        'Європа, ГОСТ': '46',
        США: 'S/L',
        Австрія: '88-92 V-VI'
    },
    {
        'Зріст (см)': '185-195',
        "Об'єм талії": '100',
        Бундесвер: '	Gr.13 ',
        'Європа, ГОСТ': '48',
        США: 'M/L',
        Австрія: '96-100 V-VI'
    },
    {
        'Зріст (см)': '185-195',
        "Об'єм талії": '105',
        Бундесвер: 'Gr.14 ',
        'Європа, ГОСТ': '50',
        США: 'L/L	',
        Австрія: '96-100 V-VI'
    },
    {
        'Зріст (см)': '185-195',
        "Об'єм талії": '110',
        Бундесвер: 'Gr.15',
        'Європа, ГОСТ': '52',
        США: 'XL/L',
        Австрія: '104-108 V-VI'
    },
    {
        'Зріст (см)': '185-195',
        "Об'єм талії": '115',
        Бундесвер: '	Gr.16',
        'Європа, ГОСТ': '54	',
        США: 'XXL/L	',
        Австрія: '104-108 V-VI'
    },
    {
        'Зріст (см)': '195-205',
        "Об'єм талії": '95',
        Бундесвер: 'Gr.17',
        'Європа, ГОСТ': '50',
        США: '– ',
        Австрія: '– '
    },
    {
        'Зріст (см)': '195-205',
        "Об'єм талії": '100',
        Бундесвер: '	Gr.18',
        'Європа, ГОСТ': '52',
        США: '–  ',
        Австрія: '– '
    },
    {
        'Зріст (см)': '195-205',
        "Об'єм талії": '105',
        Бундесвер: '	Gr.19 ',
        'Європа, ГОСТ': '54',
        США: '	– ',
        Австрія: '– '
    },
    {
        'Зріст (см)': '195-205',
        "Об'єм талії": '110',
        Бундесвер: 'Gr.20',
        'Європа, ГОСТ': '56',
        США: '– ',
        Австрія: '112-116 V-VI'
    },
    {
        'Зріст (см)': '195-205',"Об'єм талії": '115',
        Бундесвер: 'Gr.21',
        'Європа, ГОСТ': '58',
        США: '	–',
        Австрія: '112-116 V-VI'
    }
];







const jackets = [
    { 'Зріст (см)': '165-175',"Об'єм грудей": '	90',Бундесвер: '	Gr.1',Австрія: '	88-92 I-II', США: '	X/S	', 'Європа, ГОСТ': '23(44)'},
    { 'Зріст (см)': '165-175',"Об'єм грудей": '	95',Бундесвер: '	Gr.2',Австрія: '	88-92 I-II', США: '	X/M	', 'Європа, ГОСТ': '24(46)'},
    {'Зріст (см)': '165-175',"Об'єм грудей": '	100',Бундесвер: '	Gr.3',Австрія: '	96-100 I-II', США: '	X/L	', 'Європа, ГОСТ': '25(48)'},
    {'Зріст (см)': '165-175',"Об'єм грудей": '	105',Бундесвер: '	Gr.4',Австрія: '	96-100 I-II', США: '	X/XL', 'Європа, ГОСТ': '	26(50)'},
    {'Зріст (см)': '165-175',"Об'єм грудей": '	110',Бундесвер: '	Gr.5',Австрія: '	104-108 I-II', США: '	X/XXL', 'Європа, ГОСТ': '	27(52)'},
    {'Зріст (см)': '175-185',"Об'єм грудей": '	90',Бундесвер: '	Gr.6',Австрія: '	88-92 III-IV', США: '	S/R	', 'Європа, ГОСТ': '46(44)'},
    {'Зріст (см)': '175-185',"Об'єм грудей": '	95',Бундесвер: '	Gr.7',Австрія: '	88-92 III-IV', США: '	M/R', 'Європа, ГОСТ': '	48(46)'},
    {'Зріст (см)': '175-185',"Об'єм грудей": '	100',Бундесвер: '	Gr.8',Австрія: '	96-100 III-IV', США: '	L/R	', 'Європа, ГОСТ': '50(48)'},
    {'Зріст (см)': '175-185',"Об'єм грудей": '	105',Бундесвер: '	Gr.9',Австрія: '	96-100 III-IV', США: '	XL', 'Європа, ГОСТ': '	52(50)'},
    {'Зріст (см)': '175-185',"Об'єм грудей": '	110	',Бундесвер: 'Gr.10	',Австрія: '104-108 III-IV', США: '	XXL', 'Європа, ГОСТ': '	54(52)'},
    {'Зріст (см)': '175-185',"Об'єм грудей": '	115',Бундесвер: '	Gr.11	',Австрія: '104-108 III-IV', США: '	XXXL', 'Європа, ГОСТ': '	56(54)'},
    {'Зріст (см)': '185-195',"Об'єм грудей": '	95',Бундесвер: '	Gr.12',Австрія: '	88-92 V-VI', США: '	S/L	', 'Європа, ГОСТ': '98(56)'},
    {'Зріст (см)': '185-195',"Об'єм грудей": '	100',Бундесвер: '	Gr.13',Австрія: '	96-100 V-VI', США: '	M/L', 'Європа, ГОСТ': '	102(98)'},
    {'Зріст (см)': '185-195',"Об'єм грудей": '	105',Бундесвер: '	Gr.14	',Австрія: '96-100 V-VI', США: '	L/L	', 'Європа, ГОСТ': '106(102)'},
    {'Зріст (см)': '185-195',"Об'єм грудей": '	110	',Бундесвер: 'Gr.15	',Австрія: '104-108 V-VI', США: '	XL/L', 'Європа, ГОСТ': '	110(106)'},
    {'Зріст (см)': '185-195',"Об'єм грудей": '	115',Бундесвер: '	Gr.16	',Австрія: '104-108 V-VI', США: '	XXL/L	', 'Європа, ГОСТ': '114(110)'},
    {'Зріст (см)': '195-205',"Об'єм грудей": '	95',Бундесвер: '	Gr.17	',Австрія: '88-92 V-VI', США: '	– ', 'Європа, ГОСТ': '	–  '},
    {'Зріст (см)': '195-205',"Об'єм грудей": '	100',Бундесвер: '	Gr.18	',Австрія: '88-92 V-VI', США: '	– ', 'Європа, ГОСТ': '	– '},
    {'Зріст (см)': '195-205',"Об'єм грудей": '	105',Бундесвер: '	Gr.19	',Австрія: '104-108 V-VI', США: '	–', 'Європа, ГОСТ': ' 	–  '},
    {'Зріст (см)': '195-205',"Об'єм грудей": '	110	',Бундесвер: 'Gr.20	',Австрія: '112-116 V-VI', США: '	– ', 'Європа, ГОСТ': '	–  '},
    {'Зріст (см)': '195-205',"Об'єм грудей": '	115',Бундесвер: '	Gr.21	',Австрія: '112-116 V-VI', США: '	– ', 'Європа, ГОСТ': '	–  '},




];

const gloves = [
    { 'Объём ладони': '8 (20-21 см)', Європа: 8, США: 'S' },
    { 'Объём ладони': '9 (21-22 см)', Європа: 9, США: 'M' },
    { 'Объём ладони': '10 (22-23 см)', Європа: 10, США: 'L' },
    { 'Объём ладони': '11 (23-24 см)', Європа: 11, США: 'XL' },
    { 'Объём ладони': '12 (24-25 см)', Європа: 12, США: 'XXL' }
];

const socks =[
    { США: 'S',Європа: '31-35'},
    { США: 'M',	Європа: '36-40'},
    { США: 'L',	Європа: '41-45'},
    { США: 'XL',Європа: '46-49'}
]


module.exports = {
    shoes,
    pants_shorts,
    gloves,
    socks,
    jackets
}