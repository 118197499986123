let api;
process.env.NODE_ENV === 'production' ? (api = 'https://forma-nato.com.ua/') : (api = 'http://localhost:3001/');

const createNewCloth = (data, token) => {
	return fetch(`${api}api/cloth/`, {
		method: 'POST',
		body: data,
		headers: {
			authorization: `Bearer ${token}`
		}
	})
		.then(response => {
			return response.json();
		})
		.catch(err => console.log(err));
};

const getClothByName = (data, token) => {
	return fetch(`${api}api/cloth/name`, {
		method: 'POST',
		body: data,
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${token}`
		}
	}).then(response => {
		return response.json();
	});
};

const deletePhoto = (id, photo, token) => {
	return fetch(`${api}api/cloth/photo/${id}?photo=${photo}`, {
		method: 'DELETE',
		headers: {
			authorization: `Bearer ${token}`
		}
	})
		.then(response => {
			return response.json();
		})
		.catch(err => console.log(err));
};

const updateCloth = (data, token) => {
	return fetch(`${api}api/cloth/update`, {
		method: 'POST',
		body: data,
		headers: {
			authorization: `Bearer ${token}`
		}
	})
		.then(response => {
			return response.json();
		})
		.catch(err => console.log(err));
};

const getClothByCategory = (category, page) => {
	return fetch(`${api}api/cloth/public/list/${category}?page=${page}`, {
		method: 'GET'
	}).then(response => {
		return response.json();
	});
};

const getClothById = id => {
	return fetch(`${api}api/cloth/public/${id}`, {
		method: 'GET'
	}).then(response => {
		return response.json();
	});
};

const tokenCheck = token => {
	return fetch(`${api}api/auth/token-check`, {
		method: 'GET',
		headers: {
			authorization: `Bearer ${token}`
		}
	});
};

const login = data => {
	return fetch(`${api}api/auth/login`, {
		method: 'POST',
		body: data,
		headers: {
			'Content-Type': 'application/json'
		}
	}).then(response => {
		return response.json();
	});
};

const deleteById = (id, token, data) => {
	return fetch(`${api}api/cloth/del/${id}`, {
		method: 'POST',
		body: data,
		headers: {
			authorization: `Bearer ${token}`,
			'Content-Type': 'application/json'
		}
	}).then(response => {
		return response.json();
	});
};

module.exports = {
	getClothByName,
	createNewCloth,
	deletePhoto,
	updateCloth,
	getClothByCategory,
	getClothById,
	tokenCheck,
	login,
	deleteById,
	api
};
