import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './index.css';

import Home from '../Home';
import Reviews_Analytics from '../Reviews_Analytics';
import Payment from '../Payment';
import Size_chart from '../Size_chart';
import Contacts from '../Contacts';
import Sidebar from '../Sidebar';
import Products from '../Products';
import Product from '../Product';
import Login from '../Login';
import AdminRoute from '../AdminRoute';
import AdminDashboard from '../AdminDashboard';
import Article from '../Article';
import TypeCamouflage from '../TypeCamouflage';
import { article } from '../Reviews_Analytics/articles';

import routes from '../../utils/routes';

class Main extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Sidebar />
				<div className="main">
					<div className="content">
						<Switch>
							<Route exact path="/reviews-analytics" component={Reviews_Analytics} />
							<Route exact path="/size-chart" component={Size_chart} />
							<Route exact path="/payment" component={Payment} />
							<Route exact path="/contacts" component={Contacts} />

							{article.map(a => {
								return (
									<Route path={`/${a.path}`} key={a.title}>
										<Article link={a.link} paragraphs={a.paragraphs} />
									</Route>
								);
							})}

							<Route exact path="/camouflage" component={TypeCamouflage} />

							{routes.map((p, i) => {
								return (
									<Route
										exact
										path={`/${p.path}/:id`}
										key={i}
										render={props => <Products {...props} category={p.path} />}
									/>
								);
							})}

							<Route exact path="/products/:productId" component={Product} />
							<Route exact path="/login" component={Login} />
							<AdminRoute exact path="/admin-dashboard">
								<AdminDashboard />
							</AdminRoute>
							<Route path="/" component={Home} />
						</Switch>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Main;
