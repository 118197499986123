import React, { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import './index.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { shoes, pants_shorts, gloves, socks, jackets } from './data';

const Size_chart = () => {
	const [rowData, setRowData] = useState(shoes);
	const [rowData2, setRowData2] = useState(gloves);
	const [rowData3, setRowData3] = useState(pants_shorts);
	const [rowData4, setRowData4] = useState(socks);
	const [rowData5, setRowData5] = useState(jackets);

	return (
		<div className="size_chart">
			<h2>Штани, шорти</h2>
			<div className="ag-theme-alpine" style={{ height: 936, width: 865 }}>
				<AgGridReact rowData={rowData3}>
					<AgGridColumn maxWidth={130} field="Зріст (см)"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Об'єм талії"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Австрія"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Бундесвер"></AgGridColumn>
					<AgGridColumn maxWidth={180} field="Європа, ГОСТ"></AgGridColumn>
					<AgGridColumn maxWidth={100} field="США"></AgGridColumn>
				</AgGridReact>
			</div>
			<h2>Верхній одяг армійського зразка (куртки, парки, кітеля)</h2>
			<div className="ag-theme-alpine" style={{ height: 936, width: 885 }}>
				<AgGridReact rowData={rowData5}>
					<AgGridColumn maxWidth={130} field="Зріст (см)"></AgGridColumn>
					<AgGridColumn maxWidth={170} field="Об'єм грудей"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Австрія"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Бундесвер"></AgGridColumn>
					<AgGridColumn maxWidth={180} field="Європа, ГОСТ"></AgGridColumn>
					<AgGridColumn maxWidth={100} field="США"></AgGridColumn>
				</AgGridReact>
			</div>
			<h2>Розмір рукавичок</h2>
			<div className="ag-theme-alpine" style={{ height: 263, width: 507 }}>
				<AgGridReact rowData={rowData2}>
					<AgGridColumn maxWidth={200} field="Объём ладони"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Європа"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="США"></AgGridColumn>
				</AgGridReact>
			</div>
			<h2>Розмір шкарпеток</h2>
			<div className="ag-theme-alpine" style={{ height: 220, width: 303 }}>
				<AgGridReact rowData={rowData4}>
					<AgGridColumn maxWidth={150} field="Європа"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="США"></AgGridColumn>
				</AgGridReact>
			</div>
			<h2>Взуття армійського зразка</h2>
			<div className="ag-theme-alpine" style={{ height: 1270, width: 455 }}>
				<AgGridReact rowData={rowData}>
					<AgGridColumn maxWidth={150} field="США"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Європа"></AgGridColumn>
					<AgGridColumn maxWidth={150} field="Бундесвер"></AgGridColumn>
				</AgGridReact>
			</div>
		</div>
	);
};

export default Size_chart;
