import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';

import Header from './component/Header';
import Footer from './component/Footer';
import Main from './component/Main';
import './styles/style.css';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://aa976b0e91bd443191e012c42781efa1@o575464.ingest.sentry.io/5727718',
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0
	});
	ReactGA.initialize('UA-122111308-1');
	ReactGA.pageview(window.location.pathname + window.location.search);
	hotjar.initialize(2397922, 6);
}

const App = () => (
	<div className="wrapper">
		<Router>
			<Header />
			<Main />
			<Footer />
		</Router>
	</div>
);

export default App;
