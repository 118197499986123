import React from 'react';
import './index.css';

const NotFound = () => (
	<div className="container-notfound">
		<h1>На жаль, сторінка не доступна!</h1>
		<h2>Схоже ця сторінка була переміщена, видалена або зникла.</h2>
	</div>
);

export default NotFound;
