import React from 'react';
import { Link } from 'react-router-dom';
// import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import './index.css';
import Logo from '../Logo/index';

// const options = ['Головна', 'Огляди та аналітика'];

// const ITEM_HEIGHT = 48;

const Header = () => {
	// const [anchorEl, setAnchorEl] = React.useState(null);
	// const open = Boolean(anchorEl);

	// const handleClick = event => {
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	return (
		<header className="header">
			<Logo />
			{/* <div className="nav-button">
				<IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: '20ch'
						}
					}}
				>
					{options.map(option => (
						<MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
							{option}
						</MenuItem>
					))}
				</Menu>
			</div> */}
			<nav className="nav">
				<ul>
					<Link to="/">
						<li> Головна </li>
					</Link>
					<Link to="/reviews-analytics">
						<li>Огляди та аналітика</li>
					</Link>
					<Link to="/payment">
						<li>Оплата і доставка</li>
					</Link>
					<Link to="/size-chart">
						<li>Розмірна сітка</li>
					</Link>
					<Link to="/contacts">
						<li>Контакти</li>
					</Link>
				</ul>
			</nav>
		</header>
	);
};

export default Header;
