import React from 'react';
import './index.css';
import validator from 'validator';
import { Redirect } from 'react-router-dom';
import querystring from 'querystring';
import { tokenCheck, login } from '../../utils/api';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			isEmailValid: true,
			isPasswordValid: true
		};
		this.handleClickLogin = this.handleClickLogin.bind(this);
	}

	async componentDidMount() {
		if (localStorage.getItem('token') || this.props.location.search.length > 0) {
			const user = querystring.parse(this.props.location.search.slice(1)).user;
			if (user) {
				const token = JSON.parse(user).token;
				localStorage.setItem('token', token);
			}
			const token = localStorage.getItem('token');
			await tokenCheck(token).then(r => {
				r.status === 200 ? this.setState({ token: localStorage.getItem('token') }) : localStorage.clear();
			});
		}
	}

	validateEmail = () => {
		const { email } = this.state;
		const isEmailValid = !validator.isEmpty(email);
		this.setState({ isEmailValid });
		return isEmailValid;
	};

	validatePassword = () => {
		const { password } = this.state;
		const isPasswordValid = !validator.isEmpty(password);
		this.setState({ isPasswordValid });
		return isPasswordValid;
	};

	emailChanged = email => this.setState({ email, isEmailValid: true });

	passwordChanged = password => this.setState({ password, isPasswordValid: true });

	validateForm = () => [this.validateEmail(), this.validatePassword()].every(Boolean);

	async handleClickLogin(e) {
		e.preventDefault();
		const { email, password } = this.state;
		const valid = this.validateForm();
		if (!valid) {
			return;
		}
		const data = JSON.stringify({ login: email, password });
		await login(data).then(r => {
			localStorage.setItem('token', r.accessToken);
			this.setState({ token: r.accessToken });
		});
	}

	render() {
		return !this.state.token ? (
			<React.Fragment>
				<form onSubmit={this.handleClickLogin}>
					<input
						placeholder="Email"
						type="email"
						onChange={ev => this.emailChanged(ev.target.value)}
						onBlur={this.validateEmail}
					/>
					<input
						placeholder="Password"
						type="password"
						onChange={ev => this.passwordChanged(ev.target.value)}
						onBlur={this.validatePassword}
					/>
					<button type="submit">Login</button>
				</form>
			</React.Fragment>
		) : (
			<Redirect to="/admin-dashboard" />
		);
	}
}

export default Login;
